<template>
    <div class="container iphonex-pb">
        <headeBar
            title="选择参与人"
            left-arrow
            @click-left="newAppBack"
            @setBarHeitht="setBarHeitht"
            style="z-index: 9999; position: relative"
        />

        <!-- 搜索浮窗 -->
        <van-popup
            v-model="searchShow"
            position="top"
            transition="fade"
            class="search-dialog"
        >
            <div
                class="search-columnbox"
                :style="{
                    paddingTop: barHeight + 'px',
                    height: searchContentShow
                        ? 'calc(100vh - ' + keyboxHeight + 'px)'
                        : 'auto',
                }"
            >
                <div class="search row-start-center">
                    <img
                        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/myBusinessManagement/search-icon.png"
                        alt=""
                    />

                    <van-field
                        class="search-input f28"
                        ref="sh"
                        placeholder="搜索"
                        v-model="searchText"
                        @input="searchChange"
                    />
                    <span
                        @click="cancelSearch"
                        class="f28 flex-none"
                        style="color: #267dff"
                        >确认</span
                    >
                </div>
                <div class="search-content" v-show="searchContentShow">
                    <div v-show="searchList.length > 0" class="iphonex-pb">
                        <div class="list">
                            <li
                                v-for="item in searchList"
                                :key="item.userId"
                                class="staff-item row-between-center"
                                @click="choose(item)"
                            >
                                <div class="left row-start-center">
                                    <span
                                        :class="[
                                            'check-box',
                                            checkedList.includes(item.userId)
                                                ? 'checked'
                                                : '',
                                        ]"
                                    ></span>
                                    <p class="name f28 ellipsis">
                                        {{ item.userName }}
                                    </p>
                                    <span
                                        v-if="item.isChildren == '1'"
                                        class="ischild"
                                        >亲子账户</span
                                    >
                                </div>
                                <p class="phone f24">{{ item.mobile }}</p>
                            </li>
                        </div>
                        <!-- <div
                            class="iphonex-pb searchsubbox"
                            v-show="searchList.length > 0"
                        >
                            <div class="searchsub">确认选择</div>
                        </div> -->
                    </div>
                    <p v-show="searchList.length === 0" class="f32 fw6 nofinal">
                        搜索不到结果
                    </p>
                </div>
            </div>
        </van-popup>
        <div class="search row-start-center" @click="tosearch">
            <img
                src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/myBusinessManagement/search-icon.png"
                alt=""
            />
            <p class="f28">搜索团队成员</p>
            <van-field class="hackfull" placeholder="" />
        </div>
        <div class="user-content">
            <!-- 分组 -->
            <div
                class="group-item"
                v-for="group in groupList"
                :key="group.roleId"
            >
                <div class="f28 row-start-center group-title">
                    {{ group.roleName }}
                </div>
                <ul class="group-box">
                    <!-- 用户 -->
                    <li
                        v-for="(item, index) in group.staffList"
                        :key="index"
                        class="staff-item row-between-center"
                        @click="choose(item)"
                    >
                        <div class="left row-start-center">
                            <span
                                :class="[
                                    'check-box',
                                    checkedList.includes(item.userId)
                                        ? 'checked'
                                        : '',
                                ]"
                            ></span>
                            <p class="name f28 ellipsis">{{ item.userName }}</p>
                            <span v-if="item.isChildren == '1'" class="ischild"
                                >亲子账户</span
                            >
                        </div>
                        <p class="phone f24">{{ item.mobile }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div style="height:1000px" >666</div> -->
        <!-- 提交面板 -->
        <div class="submit-board iphonex-pb">
            <div class="submit-box">
                <div class="row-between-center">
                    <p class="name f28 fw6">{{ courseDetail.name }}</p>
                    <p class="f24" style="color: #267dff">
                        已选择
                        <span class="fw6"
                            >{{ checkedList.length }}/{{
                                courseDetail.maxNum
                            }}</span
                        >
                    </p>
                </div>
                <p class="p4 f20">
                    {{ courseDetail.date
                    }}<span v-if="type == 'training'"
                        ><span style="margin: 0 8px 0 4px">开营</span> 共{{
                            courseDetail.lessonNum
                        }}节</span
                    >
                </p>
                <p class="p4 f20">
                    {{ courseDetail.teacher }}
                    <span class="pt" v-if="type == 'coach'"
                        >PT{{ courseDetail.pt }}</span
                    >
                    | {{ courseDetail.venueName }}
                </p>
                <div class="iphonex-pb searchsubbox">
                    <div class="searchsub" @click="confirm">
                        确认分配<span v-show="checkedList.length"
                            >（{{ checkedList.length }}）</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';

export default {
    components: {
        headeBar,
    },
    mixins: [userMixin],
    props: {},
    data() {
        return {
            groupList: [], // 分组列表
            staffList: [],
            pageNum: 1, // 每个分组的页码 按分组一页一页请求 若该组分页结束 请求下一个分组
            checkedList: [], // 选中的id列表
            searchShow: false,
            searchContentShow: false,
            searchText: '', // 搜索的文案
            searchList: [], // 搜索出来的列表
            id: this.$route.query.id,
            type: this.$route.query.type, //lesson团课 coach私教 training训练营
            courseDetail: {
                maxNum: 0,
                name: '',
                teacher: '',
                venueName: '',
                date: '',
            },
            hasChecked: false, // 是否有 已选择的
            barHeight: 0, //头部高度
            keyboxHeight: 0,
        };
    },
    async created() {
        // this.phoneHeight = document.documentElement.clientHeight;
        // window.addEventListener('resize', this.resize, false);
        await this.$getAllInfo(); //获取userId,token
        await this.$getCompanyAdmin(); //获取企业信息

        this.getAll();
    },
    // mounted(){
    //     this.viewport = window.visualViewport;
    //     var ua = navigator.userAgent;
    //     var isAndroid = /android/i.test(ua);
    //     if(!isAndroid){
    //         if(this.viewport){
    //             window.visualViewport.addEventListener('resize',this.resize);
    //         }
    //     }
    // },
    // beforeDestroy() {
    //     window.visualViewport.removeEventListener('resize', this.resize);
    // },
    methods: {
        newAppBack,
        resize() {
            if (this.viewport) {
                const windowHeight = window.innerHeight;
                if (this.viewport.height < windowHeight - 100) {
                    this.keyboxHeight = windowHeight - this.viewport.height;
                } else {
                    this.keyboxHeight = 0;
                }
            } else {
                this.keyboxHeight = this.windowHeight - window.innerHeight;
            }
        },
        queryDetail() {
            //团课详情
            this.$axios
                .post(
                    this.baseURLApp +
                        '/scheduleLesson/lessonDetail',
                    {
                        mouldId: this.id,
                    }
                )
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.courseDetail = {
                        ...data,
                        maxNum: data.maxNum,
                        name: data.courseName,
                        date:
                            data.dataStr +
                            ' ' +
                            data.weekNum +
                            ' ' +
                            data.startTime +
                            '-' +
                            data.endTime,
                        teacher: data.teacherName,
                        venueName: data.venueName,
                    };
                });
        },
        searchChange(v) {
            if (!v.replace(/\s+/g, '')) {
                this.searchContentShow = false;
                return;
            }
            this.searchContentShow = true;
            const url = this.searchChild
                ? '/coo/staff/memberChildrenList'
                : '/coo/staff/memberList';
            this.$axios
                .post(this.baseURLApp + url, {
                    companyId: this.companyAdmin.companyId,
                    pageNum: 1,
                    pageSize: 100,
                    roleId: '',
                    userName: v,
                })
                .then((res) => {
                    this.searchList = res.data.list;
                });
        },
        getCoachDetail() {
            //私教详情
            this.$axios
                .post(
                    this.baseURLApp +
                        '/per/app/clpercoursearra/enterpriseCourseInfo',
                    {
                        perCourseArraId: this.id,
                        userId: this.userId,
                        token: this.token,
                    }
                )
                .then((res) => {
                    const data = res.data;
                    console.log(res);
                    this.courseDetail = {
                        ...data,
                        maxNum: 1,
                        name: data.projectName,
                        date: data.time,
                        teacher: data.coachName,
                        venueName: data.venueName,
                    };
                    if (data.userId) {
                        this.checkedList = [parseInt(data.userId)];
                        this.hasChecked = true;
                    } else {
                        this.hasChecked = false;
                    }
                });
        },
        getTrainingDetail() {
            //训练营详情
            return this.$axios
                .post(this.baseURLApp + '/camp/company/companyCampDetail', {
                    campAllotId: this.id,
                })
                .then((res) => {
                    const data = res.data;
                    console.log(res);
                    this.courseDetail = {
                        ...data,
                        maxNum: data.maximum,
                        name: data.courseName,
                        date: data.date,
                        teacher: data.teacherName,
                        venueName: data.venueName,
                    };
                });
        },
        getGroupList() {
            return this.$axios.post(
                this.baseURLApp + '/coo/staff/memberManageList',
                {
                    companyId: this.companyAdmin.companyId,
                    userId: this.userId,
                    token: this.token,
                }
            );
        },
        async getStaffList() {
            let r = await this.getGroupList();
            let list = r.data.roleList;
            const url = this.searchChild
                ? '/coo/staff/memberChildrenList'
                : '/coo/staff/memberList';
            this.groupList=[];
            list.map((item) => {
                this.$axios
                    .post(this.baseURLApp + url, {
                        companyId: this.companyAdmin.companyId,
                        pageNum: 1,
                        pageSize: 200,
                        roleId: item.roleId,
                        userName: '',
                    })
                    .then((res) => {
                        let list = res.data.list || [];
                        // 筛选可用的列表
                        let availableList = list.filter(
                            (item) => item.status == 1
                        );
                        let obj = {
                            roleId: item.roleId,
                            roleName: item.roleName,
                            staffList: availableList,
                        };
                        // this.staffList = this.staffList.concat(res.data.list) // 员工总列表
                        this.groupList.push(obj);
                    });
            });
        },
        getAll() {
            if (this.userId && this.token && this.companyAdmin) {
                if (this.id && this.type) {
                    // 获取员工列表
                    if (this.type == 'lesson') {
                        // 获取课程信息
                        this.getStaffList();
                        this.queryDetail();
                        this.getCheckedList();
                    } else if (this.type == 'coach') {
                        //私教
                        this.getStaffList();
                        this.getCoachDetail();
                    } else if (this.type == 'training') {
                        //训练营
                        this.getTrainingDetail().then(() => {
                            this.searchChild = this.courseDetail.childFlag == 1;
                            this.getStaffList();
                            this.getTrainingCheckedList();
                        });
                    }
                } else {
                    this.$toast('缺少参数');
                }
            }
        },
        getCheckedList() {
            // 获取团课已分配的名单
            this.$axios
                .post(this.baseURLApp + '/companyLesson/appointList', {
                    lessonId: this.id,
                    userId: this.userId,
                    token: this.token,
                })
                .then((res) => {
                    this.checkedList = res.data || [];

                    this.hasChecked = this.checkedList.length ? true : false;
                });
        },
        getTrainingCheckedList() {
            // 获取训练营已分配的名单
            this.$axios
                .post(this.baseURLApp + '/camp/company/alreadyAppointedUser', {
                    payOrderNo: this.courseDetail.payOrderNo,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == '1') {
                        this.checkedList = res.data
                            ? res.data.map((d) => {
                                  return ~~d;
                              })
                            : [];
                        this.hasChecked = this.checkedList.length
                            ? true
                            : false;
                    }
                });
        },
        choose(item) {
            let isExistIndex = this.checkedList.indexOf(item.userId);
            if (isExistIndex > -1) {
                this.checkedList.splice(isExistIndex, 1);
            } else {
                if (this.checkedList.length === this.courseDetail.maxNum) {
                    this.$toast('已超过最大容纳人数了~');
                    return;
                }
                this.checkedList.push(item.userId);
            }
        },
        async confirm() {
            if (this.checkedList.length === 0 && !this.hasChecked) {
                // 可以取消
                this.$toast('请选择');
                return;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            });
            if (this.type == 'lesson') {
                this.$axios
                    .post(this.baseURLApp + '/companyLesson/manageBuy', {
                        lessonId: this.id,
                        userId: this.userId,
                        userIdList: this.checkedList,
                    })
                    .then((r) => {
                        this.success();
                    })
                    .catch(() => {
                        this.$toast('分配失败，稍后再试');
                        this.getAll();
                    });
            } else if (this.type == 'coach') {
                this.$axios
                    .post(
                        this.baseURLApp +
                            '/per/app/clpercoursearra/assignEnterpriseCourse',
                        {
                            perCourseArraId: this.id,
                            userId:
                                this.checkedList.length > 0
                                    ? this.checkedList[0]
                                    : null,
                            token: this.token,
                            payOrderNo: this.courseDetail.payOrderNo,
                        }
                    )
                    .then((res) => {
                        if (res.code == '1') {
                            this.success();
                        } else {
                            this.$toast('分配失败，稍后再试');
                            this.getAll();
                        }
                        console.log(res);
                    });
            } else if (this.type == 'training') {
                this.$axios
                    .post(this.baseURLApp + '/camp/company/userAppoint', {
                        campAllotId: this.id,
                        userIds: this.checkedList,
                        payOrderNo: this.courseDetail.payOrderNo,
                        operator: this.userId,
                    })
                    .then((res) => {
                        if (res.code == '1') {
                            this.success();
                        } else {
                            this.$toast('分配失败，稍后再试');
                            this.getAll();
                        }
                        console.log(res);
                    });
            }
        },
        success() {
            this.$toast.clear();
            this.$toast('分配成功');
            setTimeout(() => {
                this.$router.go(-1);
            }, 1000);
        },
        tosearch() {
            this.searchShow = true;
            this.$nextTick(() => {
                this.$refs.sh.focus();
            });
        },
        cancelSearch() {
            this.searchShow = false;
            this.searchText = '';
            this.searchContentShow = false;
            this.searchList = [];
        },
        setBarHeitht(value) {
            this.barHeight = value;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.searchShow) {
            this.cancelSearch();
            next(false);
        } else {
            next();
        }
    },
};
</script>
<style lang="less" scoped>
.container {
    background-color: #f5f5f5;
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    * {
        box-sizing: border-box;
    }
    .search-input {
        padding: 0;
    }
    .search {
        width: 100%;
        height: 96px;
        margin-top: 16px;
        background: #ffffff;
        padding: 0 24px;
        position: relative;
        img {
            width: 36px;
            margin: 0 16px 0 0;
        }
        p {
            color: #9aa1a9;
        }
        /deep/ .van-field__control::-webkit-input-placeholder {
            color: #9aa1a9;
        }
        .hackfull {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            opacity: 0;
            background: transparent;
        }
    }
    .user-content {
        padding-bottom: 304px;
    }
    .group-item {
        width: 100%;
        padding: 0 24px;
        margin-top: 16px;
        background-color: #fff;
        .group-title {
            height: 88px;
            border-bottom: 1px solid #eeeeee;
        }
        .group-box {
            padding: 24px 0;
        }
    }
    .staff-item {
        padding: 24px 0;
        .check-box {
            width: 38px;
            height: 38px;
            border: 5px solid #ddd;
            border-radius: 12px;
            &.checked {
                background-size: cover;
                background-position: center;
                border: none;
                background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/myBusinessManagement/check-icon.png);
            }
        }
        .name {
            max-width: 420px;
            margin: 0 0 0 24px;
        }
        .phone {
            color: #6c727a;
        }
    }
    .submit-board {
        box-sizing: content-box;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #eee;
        .submit-box {
            padding: 24px;
            height: 304px;
            .pt {
                position: relative;
                top: 0;
                padding: 0 4px;
                border-radius: 2px;
                border: 0.5PX solid #267dff;
                color: #267dff;
                font-size: 16px;
                margin: 0 4px;
                height: 20px;
                line-height: 20px;
                display: inline-block;
            }
        }
        .p4 {
            margin: 8px 0 0;
            color: #6c727a;
        }
        .sd-button {
            position: absolute;
            bottom: 24px;
            left: 24px;
            right: 24px;
            width: auto;
        }
    }
    .search-dialog {
        background: #f5f5f5;
        .search-columnbox {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .search-content {
            background-color: #fff;
            padding: 32px 24px;
            width: 100%;
            flex: 1;
            overflow: auto;
            border-top: 1px solid #eee;
            .list {
                padding-bottom: 212px;
            }
            .nofinal {
                color: #9aa1a9;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .van-cell::after {
        border-bottom: none;
    }
    .searchsubbox {
        background: #fff;
        box-sizing: content-box;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .searchsub {
            background: #ffde00;
            border-radius: 8px;
            font-weight: bold;
            text-align: center;
            line-height: 96px;
            color: #242831;
            font-size: 32px;
            margin: 24px;
        }
    }
    .ischild {
        border: 0.5PX solid #267dff;
        color: #267dff;
        padding: 0 8px;
        font-size: 18px;
        border-radius: 1px;
        margin-left: 16px;
        height: 32px;
        line-height: 32px;
    }
}
</style>
