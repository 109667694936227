const appMixins = {
    data() {
        return {
            isAppMethodLoadOver: false,// 是否原生方法加载完成,false未加载完成 true加载完成
            appCommParams: null, // 全局公用app公用参数
        }
    },
    methods: {
        // 获取app参数值
        async getAppParams(promiseArr) {
            let appParamsArr = await Promise.all(promiseArr)
            this.isAppMethodLoadOver = true;
            let resultObj = {};
            for (let i = 0; i < appParamsArr.length; i++) {
                for (const [key, value] of Object.entries(appParamsArr[i])) {
                    resultObj[key] = value
                }
            }
            return resultObj;
        },
        isEmpty(data) {
            // console.log(typeof data)
            switch (typeof data){
                case "number":
                    return data === 0
                case "object":
                    return (!data || Object.keys(data).length < 1);
                case "undefined":
                    return true
                case "boolean":
                    return false
                case "string":
                    return (data === 'undefined' || data === 'null' || data === '' || data === '0')
            }
        }
    },
}
export default appMixins