<template>
    <van-popup v-model="show" position="bottom" safe-area-inset-bottom>
        <div class="com_courseArrangement">
            <div class="ca_title">{{title}}课程安排</div>
            <van-icon
                name="cross"
                class="ca_close"
                color="#9AA1A9"
                @click="show = false"
            />
            <div class="ca_list">
                <div class="ca_listmodel van-hairline--bottom" v-for="(item,index) in list" :key="index" >
                    <div class="">
                        <div class="l1">第 {{item.whichSection}} 节 · {{item.dateAndWeek}}</div>
                        <div class="l2">{{item.startTimeStr}}-{{item.endTimeStr}}</div>
                    </div>
                    <div class="l2">{{item.duration}} 分钟</div>
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            title:'',
            list: [],
        };
    },
    methods: {
        setaclist(item) {
            this.$axios
                .post(
                    this.baseURLApp + '/camp/company/queryCompanyCampSchedule',
                    {
                        campAllotId: item.id,
                    }
                )
                .then((res) => {
                    console.log(res);
                    if (res.code == '1') {
                        this.title=item.name;
                        this.list = res.data;
                        this.show = true;
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped >
.com_courseArrangement {
    padding: 0 32px;
    .ca_title {
        color: #242831;
        font-weight: bold;
        font-size: 36px;
        margin: 48px 0 16px;
    }
    .ca_close {
        font-size: 40px;
        position: absolute;
        top: 32px;
        right: 32px;
    }
    .ca_list {
        max-height: 80vh;
        overflow: auto;
        .ca_listmodel {
            padding: 32px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .l1 {
                color: #242831;
                font-size: 28px;
                margin-bottom: 8px;
            }
            .l2 {
                color: #6c727a;
                font-size: 24px;
            }
        }
    }
}
</style>